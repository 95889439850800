import React from "react"
import CookieConsent from "react-cookie-consent"
import { useSlateSerialiser } from "../hooks/useSlateSerialiser"
import { useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import Header from "./header"
import Footer from "./footer"

var constants = require("../../constants")

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query CookieHeading {
      webinyHeadlessCms {
        getPageHeadings(where: { page: "Cookie Banner" }) {
          data {
            pageCopy
          }
        }
      }
    }
  `)

  const cookieBannerHTML = useSlateSerialiser(
    data.webinyHeadlessCms.getPageHeadings.data.pageCopy
  )
  return (
    <>
      <CookieConsent
        enableDeclineButton
        overlay={true}
        location="bottom"
        style={{ background: "#2B373B" }}
        buttonText="I Understand"
        declineButtonText="Decline"
        cookieName={
          constants.gtmCode
            ? constants.shortName + "-gdpr-gtm"
            : constants.shortName + "-gdpr-ga"
        }
        expires={182}
        sameSite="strict"
        onAccept={() => window.location.reload()}
      >
        {parse(cookieBannerHTML)}
      </CookieConsent>
      <Header />
      {children}
      <Footer />
    </>
  )
}

export default Layout
