import React, { useState, useRef } from "react"
import tw, { styled } from "twin.macro"
import { FaBars } from "react-icons/fa"
import { GrClose } from "react-icons/gr"
import { Link } from "gatsby"
import { useOnClickOutside } from "../hooks/useOnClickOutside"

const HeaderContainer = styled.header`
  ${tw`shadow-lg bg-white py-4`};
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 2;
`
const NavContainer = tw.div`
container mx-auto justify-between lg:flex lg:items-center
`
const LeftNav = tw.nav`
flex items-center py-1 justify-between
`
const LogoContainer = tw(Link)`
cursor-pointer px-2
`
const Logo = tw.img`
h-10 lg:h-12
`
const BurgerMenuIcon = tw.i`
visible lg:invisible pl-10 px-2 text-xl cursor-pointer
`
const NavigationList = styled.ul`
  display: ${({ open }) => (open ? "block" : "none")};
  flex-direction: ${({ open }) => (open ? "column" : "row")};
  ${tw`pb-6 lg:inline-flex text-right lg:flex-row lg:p-0`};
`
const NavigationListItem = styled.li`
  ${tw`px-2 mt-1 py-1 cursor-pointer rounded font-semibold`};
  & a {
    ${tw`text-gray-800 hover:text-secondary`}
  }
`
const Header = () => {
  const ref = useRef()
  const [open, setOpen] = useState(false)
  const close = () => setOpen(false)
  useOnClickOutside(ref, () => setOpen(false))

  return (
    <HeaderContainer>
      <NavContainer>
        <LeftNav>
          <LogoContainer to="/">
            <Logo src="/images/site-logo.png" height="50px" />
          </LogoContainer>
          <BurgerMenuIcon
            onClick={() => {
              setOpen(!open)
            }}
          >
            {open ? <GrClose className="text-bold" /> : <FaBars />}
          </BurgerMenuIcon>
        </LeftNav>
        <NavigationList ref={ref} open={open}>
          <NavigationListItem onClick={close}>
            <Link
              activeStyle={{
                borderBottom: "2px solid",
                paddingBottom: "2px",
                borderColor: "var(--color-secondary)",
                color: "var(--color-secondary)",
              }}
              to="/how_might_we"
            >
              How Might We
            </Link>
          </NavigationListItem>
          <NavigationListItem onClick={close}>
            <Link
              activeStyle={{
                borderBottom: "2px solid",
                paddingBottom: "2px",
                borderColor: "var(--color-secondary)",
                color: "var(--color-secondary)",
              }}
              to="/blog"
            >
              Blog
            </Link>
          </NavigationListItem>
        </NavigationList>
      </NavContainer>
    </HeaderContainer>
  )
}

export default Header
