module.exports.name = "The Innovate Crowd"
module.exports.url = "https://www.theinnovatecrowd.com"
module.exports.title =
  "Welcome to The Innovate Crowd - We Help Make Extraordinary Teams"
module.exports.description =
  "Helping teams become extraordinary by helping leaders ask the right questions and think the right way."
module.exports.gaCode = "" //leave blank to disable
module.exports.gtmCode = "GTM-WKNM8H4" //leave blank to disable
module.exports.contentApiUrl =
  "https://d3vvn91ypcb2rt.cloudfront.net/cms/read/production"
module.exports.contentApiKey =
  "072359adfd0da002e27c6e1e1697164ce6b98d19c05566b4"
var lowercaseName = exports.name.toLowerCase()
module.exports.shortName = lowercaseName.replace(/\W/g, "")
