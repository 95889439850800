import React from "react"
import tw from "twin.macro"
import { useStaticQuery, Link } from "gatsby"

var constants = require("../../constants")

const FooterContainer = tw.footer`
w-full shadow-lg bg-gray-200 pb-4 pt-6 md:pt-12 flex flex-col mx-auto
`

const ContentContainer = tw.div`
container mx-auto px-2 flex flex-col sm:flex-row justify-between
`

const LeftColumn = tw.div`
pb-6 w-full md:text-left prose prose-sm pr-6
`

const RightColumn = tw.div`
w-full sm:pl-6 sm:text-right pb-6 prose prose-sm
`

const Copyright = tw.div`
container mx-auto w-full mt-6 text-xs text-gray-500 block text-center
`

const SiteLink = tw(Link)`
text-gray-800 hover:text-secondary text-right
`

const SectionKeyline = tw.hr`
mx-12
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query AboutHeading {
      webinyHeadlessCms {
        getPageHeadings(where: { page: "About" }) {
          data {
            id
            heading
            subHeading
          }
        }
      }
    }
  `)

  const about = data.webinyHeadlessCms.getPageHeadings.data

  return (
    <FooterContainer>
      <ContentContainer>
        <LeftColumn>
          <h3>{about.heading}</h3>
          <p>{about.subHeading}</p>
        </LeftColumn>
        <RightColumn>
          <h3>Links</h3>
          <div>
            <SiteLink to="/">Home</SiteLink>
          </div>
          <div>
            <SiteLink to="/how_might_we">How Might We?</SiteLink>
          </div>
          <div>
            <SiteLink to="/blog">Blog</SiteLink>
          </div>
          <div>
            <SiteLink to="/privacy">Privacy</SiteLink>
          </div>
        </RightColumn>
      </ContentContainer>
      <SectionKeyline />
      <Copyright>
        &copy; {new Date().getFullYear()} {constants.name}
      </Copyright>
    </FooterContainer>
  )
}

export default Footer
